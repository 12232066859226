
import React from 'react'
import { ContactContainer, ContactWrapper, ContactCard, ContactH1, ContactH2, TopLine } from './ContactElements'
import {MdEmail, MdPhone, MdLocationOn } from 'react-icons/md'

const Contact = () => {
  return (
    <ContactContainer id="contact">
      <TopLine>Artesano Design</TopLine>
      <ContactH1>Contacto y Ventas</ContactH1>
      <ContactWrapper>
        <ContactCard>
          <ContactH2>
            Escribanos a nuestro correo si desea saber más sobre nuestras 
            condiciones de venta al por mayor y al detalle.
          </ContactH2>
        </ContactCard>
        <ContactCard>
{/*           <ContactH2>
            <MdLocationOn /> San José, Costa Rica            
          </ContactH2> */}
          <ContactH2>
            <MdPhone /> Teléfonos: 
          </ContactH2>
          <ContactH2>
            Costa Rica: (506) 2280-5016              
          </ContactH2>
          <ContactH2>
            Nicaragua:  (505) 8365-1663
          </ContactH2>
          <ContactH2>
            <MdEmail /> contacto@grupofiliacolor.com
          </ContactH2>          
        </ContactCard>
      </ContactWrapper>

    </ContactContainer>
  )
}

export default Contact