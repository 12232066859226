import React, { useEffect, useState } from 'react';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn } from './navbarElements';
import { URL } from '../ButtonElement';
import { FaBars } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll';
import logoAS from '../../images/AS_logo.webp'
import {MdBrush} from 'react-icons/md'

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <img src={logoAS} alt="Artesano Design" width="160px" />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="about"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
              >Características</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="products"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
              >Presentaciones</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="colors"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-60}
              >Catálogo</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="contact"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={5}
              >Contacto</NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <URL href="https://www.paintforchange.net/"
              target="_blank"
              primary="true"
              dark="true"
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={1}
            >
              Talleres <MdBrush />
            </URL>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  )
};

export default Navbar