import React from 'react'
import {
    FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle,
    SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink, ColorLine
} from './FooterElements'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll';
import logoAS from '../../images/AS_logo.webp'
import colorLine from '../../images/patronHori.webp'

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <>
            <FooterContainer>
                <ColorLine>
                    <img src={colorLine} alt="Artesano Design" width="100%" />
                </ColorLine>
                <FooterWrap>
                <FooterLinkTitle>Más Información</FooterLinkTitle>
                    <FooterLinksContainer>                        
                        <FooterLinksWrapper>                    
                            <FooterLinkItems>                                
                                <SocialIconLink href="https://www.paintforchange.net/info.html">Paint for Change</SocialIconLink>
                                <SocialIconLink href="https://www.paintforchange.net/">Talleres</SocialIconLink>
                            </FooterLinkItems>
                            <FooterLinkItems>
                                <SocialIconLink href="https://www.facebook.com/profile.php?id=100071452156196" target="_blank">facebook</SocialIconLink>
                                <SocialIconLink href="https://www.instagram.com/artesanodesign_/">Instagram</SocialIconLink>
                            </FooterLinkItems>
                        </FooterLinksWrapper>
                    </FooterLinksContainer>
                    <SocialMedia>
                        <SocialMediaWrap>
                            <SocialLogo to='/' onClick={toggleHome}>
                                <img src={logoAS} alt="Artesano Design" width="120px" />
                            </SocialLogo>
                            <WebsiteRights>Artesano Design® {new Date().getFullYear()} Derechos reservados</WebsiteRights>
                            <SocialIcons>
                                <SocialIconLink href="https://www.facebook.com/profile.php?id=100071452156196" target="_blank" aria-label="Facebook"><FaFacebook /></SocialIconLink>
                                <SocialIconLink href="https://www.instagram.com/artesanodesign_/" target="_blank" aria-label="Instagram"><FaInstagram /></SocialIconLink>
                            </SocialIcons>
                        </SocialMediaWrap>
                    </SocialMedia>
                </FooterWrap>
            </FooterContainer>
        </>
    )
}

export default Footer