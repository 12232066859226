import React from 'react'
import florSup from "../../images/florMandala.webp";
import {ImgFlor, ImgContainer} from './Heroelements';

const ImageDiv = () => {
  return (
    <div>
        <ImgContainer>
            <ImgFlor src={florSup} alt="Artesano Design" />
        </ImgContainer>
        
    </div>
  )
}

export default ImageDiv