import React, { useState } from 'react'
import {URL} from '../ButtonElement';
import {
  ColorChartContainer, TopLine, ChartH1, ChartH2, ColorTable, ArrowDown, ArrowUp, ColorColum, ColorPastel, TH, TH2, P, Pn, Pp,
  B401, B402, B403, B404, B405, B406, B407, B408, B409, B410, B411, B412,
  B413, B414, B415, B416, B417, B418, B419, B420, B421, B422, B423, B424,
  B425, B426, B427, B428, B429, B430
} from './colorChartElements'


const ColorChart = () => {

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  }

  return (
    <ColorChartContainer id='colors'>
      <TopLine>Artesano Design</TopLine>
      <ChartH1>Paleta de 30 Colores Mate</ChartH1>
      <ChartH2>24 colores Mate</ChartH2>
      <ColorTable>
        <ColorColum>
          <table>
            <tbody>
              <tr>
                <TH><B401 /><Pn>401</Pn><P>Blanco</P></TH>
                <TH><B402 /><Pn>402</Pn><P>Amarillo Limón</P></TH>
                <TH><B403 /><Pn>403</Pn><P>Amarillo Girasol</P></TH>
                <TH><B404 /><Pn>404</Pn><P>Naranja</P></TH>
              </tr>
              <tr>
                <TH><B405 /><Pn>405</Pn><P>Rojo Básico</P></TH>
                <TH><B406 /><Pn>406</Pn><P>Rojo Carmín</P></TH>
                <TH><B407 /><Pn>407</Pn><P>Fucsia</P></TH>
                <TH><B408 /><Pn>408</Pn><P>Violeta</P></TH>
              </tr>
              <tr>
                <TH><B409 /><Pn>409</Pn><P>Azul Marino</P></TH>
                <TH><B410 /><Pn>410</Pn><P>Azul Primario</P></TH>
                <TH><B411 /><Pn>411</Pn><P>Azul Victoriano</P></TH>
                <TH><B412 /><Pn>412</Pn><P>Turquesa</P></TH>
              </tr>
            </tbody>
          </table>
        </ColorColum>
        <ColorColum>
          <table>
            <tbody>
              <tr>
                <TH><B413 /><Pn>413</Pn><P>Verde Oscuro</P></TH>
                <TH><B414 /><Pn>414</Pn><P>Verde Medio</P></TH>
                <TH><B415 /><Pn>415</Pn><P>Verde Limón</P></TH>
                <TH><B416 /><Pn>416</Pn><P>Verde Oliva</P></TH>
              </tr>
              <tr>
                <TH><B417 /><Pn>417</Pn><P>Mostaza</P></TH>
                <TH><B418 /><Pn>418</Pn><P>Siena</P></TH>
                <TH><B419 /><Pn>419</Pn><P>Café</P></TH>
                <TH><B420 /><Pn>420</Pn><P>Negro</P></TH>
              </tr>
              <tr>
                <TH><B421 /><Pn>421</Pn><P>Gris</P></TH>
                <TH><B422 /><Pn>422</Pn><P>Canela</P></TH>
                <TH><B423 /><Pn>423</Pn><P>Piel Media</P></TH>
                <TH><B424 /><Pn>424</Pn><P>Geranio</P></TH>
              </tr>
            </tbody>
          </table>
        </ColorColum>
      </ColorTable>
      <ChartH2>6 colores Pastel</ChartH2>
      <ColorPastel>
        <table>
          <tbody>
            <tr>
              <TH2><B425 /><Pn>425</Pn><P>Crema</P></TH2>
              <TH2><B426 /><Pn>426</Pn><P>Rosado Bebé</P></TH2>
              <TH2><B427 /><Pn>427</Pn><P>Rosa</P><Pp>Bugambilia</Pp></TH2>
              <TH2><B428 /><Pn>428</Pn><P>Lila</P></TH2>
              <TH2><B429 /><Pn>429</Pn><P>Celeste</P></TH2>
              <TH2><B430 /><Pn>430</Pn><P>Agua Marina</P></TH2>
            </tr>
          </tbody>
        </table>
      </ColorPastel>
      <URL href="/download/paletacolores.pdf"
        download="PaletaColores ArtesanoDesign.pdf"
        onMouseEnter={onHover}
        onMouseLeave={onHover}
        primary="true"
        dark="true"
        smooth={true}
        duration={500}
        spy={true}
        exact='true'
        offset={-8}
      >
        Descargar Catálogo en PDF {hover ? <ArrowDown /> : <ArrowUp />}
      </URL>


    </ColorChartContainer>
  )
}

export default ColorChart