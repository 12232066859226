import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 950px;
    position: relative;
    z-index: 1;

    :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;    
    background: linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,1));
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;    
    opacity: 0.30;
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HeroH1 = styled.h1`
    color: antiquewhite;
    text-align: center;
    font-size: 50px;
    text-shadow: 3px 3px 2px #353535;

    @media screen and (max-width: 960px) {
        font-size: 42px;        
    }
    @media screen and (max-width: 480px) {
        font-size: 36px;        
    }    
`;

export const HeroH2 = styled.h2`
    color: #2BBAE0;
    text-align: center;
    margin-top: 1rem;
    font-size: 36px;
    text-shadow: 3px 3px 2px #353535;
    
    @media screen and (max-width: 960px) {
        font-size: 32px;        
    }
    @media screen and (max-width: 480px) {
        font-size: 28px;        
    }    
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: antiquewhite;
    font-size: 26px;
    text-align: center;
    max-width: 610px;
    border-radius: 25px;
    background: linear-gradient(to bottom, rgba(27, 51, 90, 0.4), rgba(27, 51, 90, 0.95));
    padding: 15px;
    
    @media screen and (max-width: 960px) {
        font-size: 24px;        
    }
    @media screen and (max-width: 480px) {
        font-size: 18px;        
    }       
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;

export const HeroLogo = styled.div`
    display: flex;
    align-items: center;
    //margin-top: 2rem;
    opacity: 0.9;
`;

export const ImgHero = styled.img`
    max-width: 65%;
    height: auto;
    margin: auto;

    @media screen and (max-width: 960px) {
        max-width: 50%;       
    }
    @media screen and (max-width: 480px) {
        max-width: 40%;       
    } 
`;

export const ImgFlor = styled.img`
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: sticky;
    max-width: 280px;
    height: auto;
    @media screen and (max-width: 960px) {
        max-width: 220px;        
    }
    @media screen and (max-width: 480px) {
        max-width: 180px;       
    }   
`;

export const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0px;
    height: 20px;
    position: relative;
    z-index: 1;
    background: #1B335A;
`;