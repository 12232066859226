
export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Artesano Design',
    headline: 'Características',
    description: '',
    buttonLabel: 'Catálogo',
    imgStart: false,
    img: require('../../images/cerdito_1_peq.webp'),
    alt: 'Foto Cerdito Barro',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'home',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Artesano Design',
    headline: 'Presentaciones',
    description: 'Muy buena adherencia al cartón, madera, arcilla y otras superficies porosas.',
    buttonLabel: 'Catálogo',
    imgStart: false,
    img: require('../../images/cerditoFrente.webp'),
    alt: 'Foto Cerdito Barro',
    dark: false,
    primary: false,
    darkText: true
}
