import React, { useState } from 'react';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroH2, HeroP, HeroBtnWrapper, HeroLogo, ArrowForward, ArrowRight, ImgHero} from './Heroelements';
import Video from '../../videos/mandala.mp4';
import { Button } from '../ButtonElement';
import logoArtesano from "../../images/logoArtesano.webp";


const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg autoPlay muted loop src={Video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroLogo>
                    <ImgHero src={logoArtesano} alt="Artesano Design" />                                           
                </HeroLogo>
                <HeroH1>
                    Témpera acrílica Artesano Design
                </HeroH1>
                <HeroH2>
                    Aprende, Desarrolla y Disfruta
                </HeroH2>
                <HeroP>
                    Artesano Design es una témpera acrílica mate y opaca de gran cubrimiento y
                    buena adherencia a superficies porosas. Es muy adecuada para
                    todo tipo de trabajos en la escuela
                    y colegios ( Artes Plásticas) y en proyectos de artesanía.
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="contact"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary="true"
                        dark="true"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-8}                          
                    >
                        Comprar {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>                    
                </HeroBtnWrapper>                
            </HeroContent>            
        </HeroContainer>
    )
}

export default HeroSection