import styled from "styled-components";
import {Link} from "react-scroll";

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#F48E18' : '#1B335A')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 46px' : '12px 28px')};
    color: ${({dark}) => (dark ? '#442E1E' : 'antiquewhite')};
    font-size: ${({fontBig}) => (fontBig ? '22px' : '18px')};
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#f1db00' : '#2BBAE0' )};
    }
`;

export const URL = styled.a`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#F48E18' : '#1B335A')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 46px' : '12px 28px')};
    color: ${({dark}) => (dark ? '#442E1E' : 'antiquewhite')};
    font-size: ${({fontBig}) => (fontBig ? '22px' : '18px')};
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#f1db00' : '#2BBAE0' )};
    }
`;