import React from 'react'
import { Button } from '../ButtonElement'
import { InfoContainer, InfoWrapper, InfoRow, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Column1, Column2, ImgWrap, Img, InfoList, IList } from './InfoElements' 

const InfoSection = ({lightBg
    , id, imgStart, topLine, lightText, darkText, headline, description, buttonLabel, img, alt, primary, dark, dark2}) => {

  return (
    <>
    <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
            <InfoRow imgStart={imgStart}>
                <Column1>
                <TextWrapper>
                    <TopLine>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headline}</Heading>
                    <Subtitle darkText={darkText}>{description}</Subtitle>                
                   <InfoList>
                        <IList>Acabado mate y opaco.</IList>
                        <IList>Muy buena adherencia al cartón, madera, arcilla y otras superficies porosas.</IList>
                        <IList>Buena resistencia al agua al secar (por tanto mancha si cae en la tela).</IList>
                        <IList>Alta cantidad de pigmentos con buena resistencia a la luz U.V.</IList>
                        <IList>Seca muy rápido.</IList>
                        <IList>No tóxica.</IList>
                    </InfoList>
                    <BtnWrap>
                            <Button to="colors" 
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            primary={primary ? 1 : 0}
                            dark={dark ? 1 : 0}
                            dark2={dark2 ? 1 : 0}
                            >{buttonLabel}</Button>
                    </BtnWrap>
                </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                        <Img src={img} alt={alt} />
                    </ImgWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
    </InfoContainer>
    </>
  )
}

export default InfoSection