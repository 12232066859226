import styled from "styled-components"
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav  = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#1B335A' : 'rgba(27, 51, 90,0.3)')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-midth: 960px) {
        transition: 0.8s all ease;
    }
`;
export const NavbarContainer = styled.div`
    display:flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 8px;
    font-weight: bold;
    text-decoration: none;
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 960px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: antiquewhite;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 960px) {
        display: none;
    }

`;

export const NavItem = styled.li`
    height: 70px;
`;

export const NavLinks = styled(LinkS)`
    color: antiquewhite;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    text-shadow: 3px 3px 2px #353535;

    &.active{
        border-bottom: 3px solid #F48E18;
    }

    &:hover{
        color: #F48E18;
        transition: all 0.2s ease-in-out;
        text-shadow: 3px 3px 3px #151515;
    }
`;

export const NavBtn = styled.nav`
  display : flex;
  align-items: center;
  margin-left: 10px;

  @media screen and (max-width: 960px){
    display: none;
  }
`;
