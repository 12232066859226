import React, {useState} from 'react'
import Footer from '../components/footer';
import HeroSection from '../components/heroSection';
import ImageDiv from '../components/heroSection/imageDiv';
import InfoSection from '../components/infoSection';
import { homeObjOne } from '../components/infoSection/Data';
import Navbar from '../components/navbar';
import Services from '../components/Services';
import Sidebar from '../components/sidebar';
import ColorChart from '../components/colorChart';
import Contact from '../components/contact';


const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle =() => {
        setIsOpen(!isOpen);
    }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle}/>
      <HeroSection />
      <ImageDiv />      
      <InfoSection {...homeObjOne}/>
      <Services />
      <ColorChart />
      <Contact />
      <Footer /> 
    </>
  )
}

export default Home