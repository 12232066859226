import styled from "styled-components";
import { MdKeyboardArrowDown, MdDownload } from 'react-icons/md'

export const ColorChartContainer = styled.div`
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1B335A;

    @media screen and (max-width: 1024px) {
        height: 960px;
    }
    @media screen and (max-width: 768px) {
        height: 1360px;
    }
`;

export const ColorTable = styled.div`
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 10px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0px 0px;
    }
`;

export const ColorColum = styled.div`
    color: antiquewhite;
    background: rgba(3,19,43,0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 390px;
    width: 380px;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.5);

    @media screen and (max-width: 420px) {
        width: 350px;
        padding: 10px;
    }
`;


export const ColorPastel = styled.div`
    color: antiquewhite;
    background: rgba(3,19,43,0.7);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 140px;
    width: 396px;
    padding: 16px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.5);
    margin-bottom: 2rem;

    @media screen and (max-width: 420px) {
        padding: 6px;
        width: 360px;
    }
`;

export const TH = styled.th`
    float: inline-start;
    vertical-align: top;
    padding: 2px;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    height: 120px;
`;

export const TH2 = styled.th`
    padding: 2px;
    margin-left: auto;
    margin-right: auto;
    vertical-align: top;
    width: 60px;
    height: 120px;
`;

export const ChartH1 = styled.h1`
    font-size: 48px;
    color: antiquewhite;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }
    @media screen and (max-width: 530px) {
        font-size: 28px;
    }
`;

export const ChartH2 = styled.h2`
    font-size: 27px;
    color: antiquewhite;
    margin-bottom: 10px;

    @media screen and (max-width: 530px) {
        font-size: 20px;
    }
`;

export const TopLine = styled.p`
    color: #2BBAE0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const ArrowDown = styled(MdDownload)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowUp = styled(MdKeyboardArrowDown)`
    margin-left: 8px;
    font-size: 20px;
`;

export const P = styled.p`
    font-size: 15px;
    
    @media screen and (max-width: 530px) {
        font-size: 12px;
        font-weight: 200;
    }    
`;

export const Pn = styled.p`
    font-size: 19px;
    font-weight: 350;
    padding: 3px;
    @media screen and (max-width: 530px) {
        font-size: 14px;
        font-weight: 200;
    }  
`;

export const Pp = styled.p`
    font-size: 12px;
    font-weight: 200;
    @media screen and (max-width: 530px) {
        font-size: 10px;
        font-weight: 150;
    }    
`;

export const B401 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(255, 255, 255), rgba(221, 225, 255));
    margin: auto;
    border-radius: 8px;
`;

export const B402 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(241, 219, 0), rgba(255, 255, 127));
    margin: auto;
    border-radius: 8px;
`;

export const B403 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(255, 176, 0), rgba(255, 204, 0));
    margin: auto;
    border-radius: 8px;
`;

export const B404 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(255, 104, 0), rgba(255, 149, 0));
    margin: auto;
    border-radius: 8px;
`;

export const B405 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(209, 51, 0), rgba(255, 59, 0));
    margin: auto;
    border-radius: 8px;
`;

export const B406 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(192, 0, 34), rgba(243, 0, 44));
    margin: auto;
    border-radius: 8px;
`;

export const B407 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(209, 47, 136), rgba(250, 56, 163));
    margin: auto;
    border-radius: 8px;
`;

export const B408 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(77, 43, 154), rgba(103, 58, 209));
    margin: auto;
    border-radius: 8px;
`;

export const B409 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(22, 21, 140), rgba(38, 38, 255));
    margin: auto;
    border-radius: 8px;
`;

export const B410 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(0, 59, 158), rgba(0, 79, 206));
    margin: auto;
    border-radius: 8px;
`;

export const B411 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(51, 105, 187), rgba(62, 129, 230));
    margin: auto;
    border-radius: 8px;
`;

export const B412 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(0, 167, 173), rgba(0, 223, 230));
    margin: auto;
    border-radius: 8px;
`;


export const B413 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(0, 95, 74), rgba(0, 153, 117));
    margin: auto;
    border-radius: 8px;
`;

export const B414 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(0, 133, 64), rgba(0, 176, 82));
    margin: auto;
    border-radius: 8px;
`;

export const B415 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(46, 216, 0), rgba(55, 255, 0));
    margin: auto;
    border-radius: 8px;
`;

export const B416 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(80, 151, 0), rgba(115, 216, 0));
    margin: auto;
    border-radius: 8px;
`;

export const B417 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(205, 161, 14), rgba(247, 193, 16));
    margin: auto;
    border-radius: 8px;
`;

export const B418 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(182, 111, 38), rgba(239, 114, 49));
    margin: auto;
    border-radius: 8px;
`;

export const B419 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(136, 89, 69), rgba(211, 136, 107));
    margin: auto;
    border-radius: 8px;
`;

export const B420 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid gray;
    background: linear-gradient(to bottom, rgba(7, 7, 7), rgba(37, 37, 37));
    margin: auto;
    border-radius: 8px;
`;

export const B421 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(145, 150, 153), rgba(181, 187, 191));
    margin: auto;
    border-radius: 8px;
`;

export const B422 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(171, 119, 79), rgba(217, 151, 100));
    margin: auto;
    border-radius: 8px;
`;

export const B423 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(216, 174, 144), rgba(255, 206, 170));
    margin: auto;
    border-radius: 8px;
`;

export const B424 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(216, 111, 106), rgba(255, 132, 125));
    margin: auto;
    border-radius: 8px;
`;

export const B425 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(237, 227, 120), rgba(255, 245, 167));
    margin: auto;
    border-radius: 8px;
`;

export const B426 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(213, 130, 137), rgba(255, 156, 164));
    margin: auto;
    border-radius: 8px;
`;

export const B427 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(208, 104, 175), rgba(255, 128, 215));
    margin: auto;
    border-radius: 8px;
`;

export const B428 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(174, 93, 208), rgba(213, 114, 255));
    margin: auto;
    border-radius: 8px;
`;

export const B429 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(87, 172, 217), rgba(101, 201, 254));
    margin: auto;
    border-radius: 8px;
`;

export const B430 = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid black;
    background: linear-gradient(to bottom, rgba(9, 203, 200), rgba(12, 255, 251));
    margin: auto;
    border-radius: 8px;
`;
