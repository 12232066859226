import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap } from './sidebarElements';
import {MdBrush} from 'react-icons/md'
import { URL } from '../ButtonElement';

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>Características</SidebarLink>
                    <SidebarLink to="products" onClick={toggle}>Presentaciones</SidebarLink>
                    <SidebarLink to="colors" onClick={toggle}>Catálogo</SidebarLink>
                    <SidebarLink to="contact" onClick={toggle}>Contacto</SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                <URL href="https://www.paintforchange.net/"
                            target="_blank"
                            primary="true"
                            dark="true"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-8}
                        >
                            Talleres <MdBrush />
                        </URL>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>

    );
};

export default Sidebar;