
import React from 'react'
import { ServicesContainer, ServicesWrapper, ServicesCard, ServicesH1, ServicesH2, ServicesP, ServicesIcon, TopLine } from './ServicesElements'
import Icon1 from '../../images/potesArtesano.webp'
import Icon2 from '../../images/ArtesanoPastel.webp'
import Icon3 from '../../images/ArtesanoBasico.webp'
import Icon4 from '../../images/ArtesanoLibro.webp'

const Services = () => {
  return (
    <ServicesContainer id="products">
        <TopLine>Artesano Design</TopLine>
        <ServicesH1>Presentaciones</ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={Icon1} />
                <ServicesH2>Colores Mate en botellas de 65 ml</ServicesH2>
                <ServicesP>Con 30 Colores</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon2} />
                <ServicesH2>Juego Colores Pastel</ServicesH2>
                <ServicesP>Cada set contiene 6 frascos de 26ml apróximadamente</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon3} />
                <ServicesH2>Juego Colores Básicos</ServicesH2>
                <ServicesP>Cada set contiene 6 frascos de 26ml apróximadamente</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon4} />
                <ServicesH2>Libro de Mandalas</ServicesH2>
                <ServicesP>Con 8 diseños y 4 hojas blancas en papel de Opalina</ServicesP>
            </ServicesCard>                   
        </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services